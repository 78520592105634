import React from "react"
import loadable from "@loadable/component"
const DynamicChat = loadable(() =>
  import("../../components/layouts/layout-chooseMode")
)

/**
 * Demo Iframe für den Förderantrag für Elektrofahrzeuge
 * - TTS über über einen Button
 * - Restart Button
 * - Leiste oben
 * - keine border
 * - läuft auf demo-prod-02
 *
 */

function Chat() {
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot
  return (
    <DynamicChat
      botId1="ddxt77ewknhgdfez"
      // token1="3jwgou3DF10.A35SD0t0jPu-rCUgveaj4VUNYjOyV_Afesxi5A9J02c"
      token1="-BeR0fBw97I.tXA01fMOkW4qALJZ9KvPzRUM4Y7Smfi0xrYi2xus8-s"
      border1={false}
      botId2="ddxyk25skp84mgrg"
      token2="-BeR0fBw97I.tXA01fMOkW4qALJZ9KvPzRUM4Y7Smfi0xrYi2xus8-s"
      border2={false}
    />
  )
}

export default Chat
